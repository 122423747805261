<template>
  <AtomsGlobalDialog :is-open="dialog?.isOpen" :is-message-container="dialog?.isMessageContainer" :hide-close-icon="true" class="w-[600px]" overlay-class="!bg-neutral-500">
    <div class="px-4 tablet:px-10">

      <!-- heading -->
      <strong class="block mb-8 text-xl tracking-normal">{{ $t('Pokračováním potvrzuji, že jsem starší 18 let') }}</strong>

      <!-- buttons -->
      <div class="flex flex-wrap gap-4 justify-between">
        <MoleculesButtonsButton :ghost="true" @click="goBack" class="w-full tablet:w-auto">{{ $t('Odejít') }}</MoleculesButtonsButton>
      <MoleculesButtonsButton @click="confirm" class="w-full tablet:w-auto">{{ $t('Rozumím, pokračovat') }}</MoleculesButtonsButton>
      </div>

    </div>
  </AtomsGlobalDialog>
</template>
<script setup>

const dialog = computed(() => useDialog().get('adultOnly'));

const goBack = () => window.history.go(-1);

const confirm = () => {
  useCookie('adultConfirmed').value = true;
  useDialog().close();
}

</script>